import React, { useState } from 'react';
import Logo from "../assets/newlogosaque.png";
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const Whats = () => (
    window.open("https://linktr.ee/saquecred")
  );

  return (
    <header className="bg-[#000000] mm:h-[60px] lg:h-[80px] flex flex-col lg:flex-row font-Marri justify-center items-center shadow-md">
      <div className="container  mx-auto lg:px-[12%] py-2 flex justify-between items-center">
        <div className="text-2xl font-bold">
          <Link to={"/"}>
            <img src={Logo} alt="Logo" className="mm:h-10 lg:h-20" />
          </Link>
        </div>
        <div className="hidden lg:flex justify-center items-center space-x-10">
          <Link to={"/"} className="text-white hover:border-b-2 transition">Home</Link>
          <Link onClick={Whats} to={"/"} className="text-white hover:border-b-2 transition">Contato</Link>
          <a href="#service" className="text-white hover:border-b-2 transition">Serviços</a>
          <a href="https://www.instagram.com/saque.cred?igsh=MXh4OTR0N3FrZnplcg%3D%3D" target='_blank' rel='noreferrer'>
            <AiFillInstagram className='text-white' />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61555608397460" target='_blank' rel='noreferrer'>
            <FaFacebook className='text-white' size={14} />
          </a>
        </div>
        <div className="lg:hidden flex">
          <button onClick={() => setMenuOpen(!menuOpen)} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
            </svg>
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className="lg:hidden bg-[#000000] fixed top-12 left-0 w-full h-auto z-20 px-4 pt-4 pb-4 space-y-5">
          <a href="/" className="block text-white hover:border-b-2 transition">Home</a>
          <a onClick={Whats} href="/" className="block text-white hover:border-b-2 transition">Contato</a>
          <a href="/servicos" className="block text-white hover:border-b-2 transition">Serviços</a>
        </div>
      )}
    </header>
  );
};

export default Header;
